'use client'

import Button from '@dg/common/components/Button'
import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	XMarkIcon
} from '@heroicons/react/24/outline'
import {
	ReactNode, type RefObject
} from 'react'

interface TooltipViewProps {
	border: boolean;
	className: string;
	header: boolean;
	height: string;
	id: string;
	maxHeight: string;
	rounded: boolean;
	text: ReactNode;
	title: ReactNode;
	tooltipCloseFunc: () => void;
	tooltipRef: RefObject<HTMLDivElement | null>;
	tooltipZIndex: string;
	transition?: boolean;
	width: string;
	wrapHidden: boolean;
	wrapLeft: number;
	wrapOpacity: boolean;
	wrapTop: number;
}

const TooltipView = ({
	tooltipRef,
	title,
	text,
	id,
	className,
	header,
	rounded,
	border,
	wrapHidden,
	wrapOpacity,
	wrapTop,
	wrapLeft,
	tooltipZIndex,
	width,
	height,
	maxHeight,
	transition = true,
	tooltipCloseFunc
}: TooltipViewProps) => {
	const customClassName =
		classNameFunc(`
			absolute flex min-w-32 max-w-9/10 flex-col overflow-hidden bg-dg-white
			text-sm shadow-lg shadow-dg-black/10
			dark:bg-dgdark-white dark:shadow-dgdark-black/10
			${transition === true ? `transition-opacity` : ``}
			${border === true ? `border border-solid border-dg-300 dark:border-dgdark-300` : ``}
			${rounded === true ? `rounded-2xl` : ``}
			${wrapOpacity === true ? `opacity-0` : `opacity-100`}
			${tooltipZIndex}
			${width}
			${height}
			${maxHeight}
			${className}
		`)

	return (
		<div
			className={
				classNameFunc(`
					tooltip-wrap
					${wrapHidden === true ? `invisible overflow-hidden` : ``}
				`)
			}
		>
			<div
				className={customClassName}
				id={id}
				ref={tooltipRef}
				style={
					{
						left: wrapLeft,
						top: wrapTop
					}
				}
			>
				{
					header === true && (
						<div
							className={
								classNameFunc(`
									flex min-h-9 justify-end border-b-2 border-solid border-dg-200
									dark:border-dgdark-200
								`)
							}
						>
							<strong
								className="order-1 flex flex-auto items-center px-4 py-2"
							>
								{
									typeof title !== `string` ?
										title :
										(
											<span
												dangerouslySetInnerHTML={
													{
														__html: title
													}
												}
											/>
										)
								}
							</strong>

							<Button
								className="order-2 block min-h-full w-10 flex-none p-2"
								onClick={tooltipCloseFunc}
								hover
							>
								<XMarkIcon
									className="size-6"
								/>

								<span
									className="sr-only"
								>
									Close Button
								</span>
							</Button>
						</div>
					)
				}

				<div
					className={
						classNameFunc(`
							flex-auto overflow-y-auto p-4
							${header === false ? `flex` : ``}
						`)
					}
				>
					{
						typeof text !== `string` ?
							text :
							(
								<div
									dangerouslySetInnerHTML={
										{
											__html: text
										}
									}
								/>
							)
					}

					{
						header === false && (
							<Button
								className="-mr-2 -mt-2 block size-10 min-h-full flex-none p-2"
								onClick={tooltipCloseFunc}
								hover
							>
								<XMarkIcon />

								<span
									className="sr-only"
								>
									Close Button
								</span>
							</Button>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default TooltipView
