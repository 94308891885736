import {
	apiFunc
} from '@dg/common/lib/common'

export interface JoinAdditionalApiProps {
	code: number;
	message?: string;
	result: {
		addr: {
			addr_front: string;
			addr_last: string;
			nation_cd: string;
			zip_code: string;
		};
		birth_date: string;
		bottom_banner: {
			link: string;
			src: string;
			title: string;
		};
		coupon_banner: {
			link: string;
			src: string;
			title: string;
		};
		hp_email: string;
		hp_no: string;
		jp_recv_nm_first: string;
		jp_recv_nm_last: string;
		login_id: string;
		nation_cd: string;
		news_letter_weekly_count: number;
	};
}

/* 회원 추가 정보 입력 페이지 */
const joinAdditionalApi = async () => {
	const response = await apiFunc(`/api/me/join/additional`)

	return response.data as JoinAdditionalApiProps
}

export interface JoinBannersApiProps {
	code: number;
	message?: string;
	result: {
		link: string;
		src: string;
		title: string;
	};
}

/* 회원 정보 입력 페이지 배너 */
const joinBannersApi = async () => {
	const response = await apiFunc(`/api/member/join/banners`)

	return response.data as JoinBannersApiProps
}

export interface JoinEmailEncApiProps {
	code: number;
	message?: string;
	result: boolean;
}

/* 가입 시 이메일 변경 */
const joinEmailEncApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/join/email/enc`, `post`, Object.fromEntries(formData))

	return response.data as JoinEmailEncApiProps
}

export interface JoinMailConfirmApiProps {
	code: number;
	message?: string;
	result: {
		app_yn: string;
		email: string;
		enc_cust_no: string;
		enc_email: string;
		login_id: string;
		redirect_type_code: string;
		redirect_url: string;
		valid_key: string;
	};
}

/* 메일 인증 */
const joinMailConfirmApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/join/mail/confirm`, `post`, Object.fromEntries(formData))

	return response.data as JoinMailConfirmApiProps
}

export interface JoinPcApiProps {
	code: number;
	message?: string;
	result: {
		confirm_url: string;
		enc_cust_info: string;
		enc_cust_no: string;
		login_id: string;
	};
}

/* 회원 가입 */
const joinPcApi = async (formData: URLSearchParams, login = false) => {
	const response =
		await apiFunc(`/api/${login === true ? `me` : `member`}/join/pc`, `post`, Object.fromEntries(formData))

	return response.data as JoinPcApiProps
}

export interface JoinPcMailConfirmApiProps {
	code: number;
	message?: string;
	result: {
		confirm: boolean;
	};
}

/* 메일 인증 확인 */
const joinPcMailConfirmApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/join/pc/mail/confirm?${formData.toString()}`)

	return response.data as JoinPcMailConfirmApiProps
}

export interface JoinReMailApiProps {
	code: number;
	message?: string;
	result: {
		confirm: boolean;
	};
}

/* 메일 다시 보내기 */
const joinReMailApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/join/re-mail`, `post`, Object.fromEntries(formData))

	return response.data as JoinReMailApiProps
}

export interface JoinReSendApiProps {
	code: number;
	message?: string;
	result: {
		confirm: boolean;
	};
}

/* 메일 다시 보내기 - 회원 가입 전용 */
const joinReSendApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/member/join/email/resend`, `post`, Object.fromEntries(formData))

	return response.data as JoinReSendApiProps
}

/* 로그인 배너 */
export interface LoginBannersApiProps {
	code: number;
	message?: string;
	result: {
		link: string;
		src: string;
		title: string;
	};
}

const loginBannersApi = async () => {
	const response = await apiFunc(`/api/legacy/login/pc/banners`)

	return response.data as LoginBannersApiProps
}

export interface ValidationPasswordApiProps {
	code: number;
	message?: string;
	result: {
		success: boolean;
		validKey: string;
	};
}

/* Password 체크 */
const validationPasswordApi = async (formData: URLSearchParams) => {
	const response = await apiFunc(`/api/me/validation/password`, `post`, Object.fromEntries(formData))

	return response.data as ValidationPasswordApiProps
}

export {
	joinAdditionalApi,
	joinBannersApi,
	joinEmailEncApi,
	joinMailConfirmApi,
	joinPcApi,
	joinPcMailConfirmApi,
	joinReMailApi,
	joinReSendApi,
	loginBannersApi,
	validationPasswordApi
}
